<template>
  <div class="innovations">
    <div class="navbar">
      <Brand />
      <BackBtn
          @click="back()"
          title="Zum Autohaus" />
    </div>
    <h1 class="order">
      Lösungen ({{ state.products.length }})
      <br><small>unserer Innovationspartner!</small>
    </h1>
    <div class="products">
      <ul>
        <transition-group
            name="fade"
            appear>
          <li
              v-for="(product, idx) in state.products"
              :key="product.id"
              :style="{ animationDelay: `${idx * 0.1}s` }"
              @click="showOverlay(product.id)">
            <div class="img-container">
              <img
                :src="`${state.basePath}images/${product.logo}`"
                :alt="product.title"
                v-if="product.logo">
              <img
                  src="../assets/img/placeholder-light.png"
                  :alt="product.title"
                  v-if="!product.logo">
            </div>
            <span v-if="false" class="id" v-text="idx + 1" />
            <span class="title" v-text="product.title" />
          </li>
        </transition-group>
      </ul>
    </div>
    <svg
        viewBox="0 0 825 103.6"
        class="footer">
      <polygon class="st0" points="-5.5,31 234.4,96.5 836.4,29.8 836.4,116.3 -5.5,117.4" />
      <polygon class="st1" points="-90,-2.9 609,93.1 828,45.1 914,58.1 865,167.1 530,173.1 366,210.1 23,71.1" />
    </svg>
    <Overlay
        v-if=" state.overlay"
        :id="state.pid"
        @close="closeOverlay" />
  </div>
</template>


<script>
import { reactive, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import Brand from '@/components/Brand'
import BackBtn from '@/components/BackBtn'
import Overlay from '@/components/Overlay'

export default {
  name: 'Innovations',

  components: {
    Brand,
    BackBtn,
    Overlay
  },

  setup () {
    const instance = getCurrentInstance()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const axios    = instance.appContext.config.globalProperties.axios
    const router   = useRouter()
    const route    = useRoute()
    const state    = reactive({
      publicPath: process.env.BASE_URL,
      basePath: process.env.VUE_APP_API_ENDPOINT,
      products: [],
      pid: null,
      overlay: false
    })

    const getProducts = async () => {
      emitter.emit('loading-start')
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}products`)
        .then(response => {
          state.products = response.data.sort((a, b) => a.title.localeCompare(b.title))
          createToast(
            'Innovationen geladen!',
            { type: 'default', position: 'bottom-right', showIcon: true }
          )
          emitter.emit('loading-stop')
        })
        .catch(() => {
          createToast(
            {
              title: 'Innovationen konnten nicht geladen werden!'
            },
            {
              type: 'danger',
              position: 'bottom-right',
              showIcon: true
            }
          )
          emitter.emit('loading-stop')
        })
    }

    const back = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Autohaus',
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const showOverlay = (pid) => {
      state.pid = parseInt(pid)
      state.overlay = true
    }

    const closeOverlay = () => {
      state.pid = null
      state.overlay = false
    }

    onMounted(() => getProducts())

    return {
      back,
      route,
      state,
      showOverlay,
      closeOverlay
    }
  }
}
</script>


<style lang="scss" scoped>
.innovations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(to top, #fff 0%, #85a7c2 100%);
  background: url('/static/img/bg-products.jpg') no-repeat center center;
  background-size: cover;

  .navbar {
    background-color: #fff;
    height: 5.5rem;
  }

  .order {
    position: fixed;
    z-index: 10;
    left: 1.5rem;
    bottom: 1.25rem;
    color: #000;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 0.65em;
    pointer-events: none;

    small {
      font-weight: 600;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: #000;
    }
  }

  .products {
    height: 100vh;
    overflow: auto;
    padding: 1rem 1rem 10rem 1rem;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 1rem;

      li {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        min-width: 100%;
        overflow: hidden;
        border-bottom: 1px solid rgba($color: #000, $alpha: 0.15);
        position: relative;
        cursor: pointer;
        transition: all 250ms ease-in-out;

        &:hover {
          opacity: 0.5;
        }

        .img-container {
          width: 100%;
          height: 0;
          display: block;
          clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
          width: 100%;
          padding-top: 100%;
          background-color: #fff;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            top: calc(50% - 2rem);
            left: calc(50% - 2rem);
            transform: translate(-50%, -50%);
            max-width: calc(100% - 4rem);
            max-height: calc(100% - 4rem);
            margin: 2rem;
          }
        }

        .title {
          display: block;
          padding: 0.75rem 0;
          flex-grow: 1;
          line-height: 1.25rem;
          font-weight: 600;
          color: #000;
        }

        .id {
          position: absolute;
          width: 2rem;
          height: 2rem;
          left: 1rem;
          top: 1rem;
          border-radius: 1rem;
          background-color: #333;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
        }
      }
    }
  }

  svg.footer {
    position: absolute;
    bottom: 0;
    pointer-events: none;

    .st0{
      fill: #fff;
      opacity: 1;
    }

    .st1{
      opacity: 0.5;
      fill: #fff;
    }
  }
}
</style>
